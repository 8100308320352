import { defineStore, acceptHMRUpdate } from 'pinia'

export const useGameStore = defineStore('gameStore', {
  state: () => ({
    gameItem: {},
    provider: null,
    providerGames: [],
    providerCasino: [],
    lotteryGameSubmenu: [],
    tableGameSubmenu: [],
    jackpotGameSubmenu: [],
    gameLists: [],
    currentRoute: null,
    resultProvider: null,
    placeholderCountLobby: 0
  }),
  actions: {
    mutate(payload) {
      this.$state[payload.property] = payload.with
    },
    setProvider(payload) {
      this.provider = payload
    },
    setProviderGames(payload) {
      this.providerGames = payload
    },
    setProviderCasino(payload) {
      this.providerCasino = payload
    },
    setGameItem(payload) {
      this.gameItem = payload
    },
    setGameLists(payload) {
      this.gameLists = payload
    },
    setPlaceholderCount(payload) {
      this.placeholderCountLobby = payload
    },
    setCurrentRoute(payload) {
      this.currentRoute = payload
    },
    setResultProvider(payload) {
      this.resultProvider = payload
    },
    setLotteryGameSubmenu(payload) {
      this.lotteryGameSubmenu = payload
    },
    setTableGameSubmenu(payload) {
      this.tableGameSubmenu = payload
    },
    setJackpotGameSubmenu(payload) {
      this.jackpotGameSubmenu = payload
    }
  },
  persist: true
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGameStore, import.meta.hot))
}
